@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300&family=Nunito&display=swap');

body {
    margin: 0;
    font-family: 'Nunito', sans-serif;
    background-color: #F0F0F0;
}

ul,li {
    padding: 0;
    list-style-type: none;
}

a {
    text-decoration: none;
}

.dBlock {
    display: block;
}

.dNone {
    display: none;
}

.container {
    width: 1300px !important;
    margin: 0 auto !important;
}

.load {
    padding-right: 20px;
}

.pt-4 {
    padding-top: 40px;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"]:hover,
input[type="number"]:focus {
    -moz-appearance: number-input;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.main_modal {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.0);
    overflow-y: auto;
    z-index: 5;
}

.modal_my {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}



.checkContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* Hide the browser's default checkbox */
.checkContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkContainer:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkContainer input:checked ~ .checkmark {
    background-color: #47CB7B;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkContainer input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkContainer .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.my-container-padding {
    box-sizing: border-box;
    padding: 0 5%  0 20%;
}

.my-container-student {
    box-sizing: border-box;
    padding: 0 5%;
}


/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
    width: 10px; /* ширина для вертикального скролла */
    height: 8px; /* высота для горизонтального скролла */
    border-radius: 5px;
    background-color: #f3f3f3;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
    background-color: #222222;
    border-radius: 5px;
}


/* Прелоадер */
.preloader-wrap {
    padding: 280px 0;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
}



.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}
.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #222222;
    margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}
.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}
.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}
.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}
.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}
.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}
.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}
.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/*-------------- прелоадер конец ----------*/

.page-item {
    margin-right: 3px;
}

.page-link {
    color: #27AE60;
    border: none;
}

.page-link:hover {
    color: #222222;
}


.page-item.active .page-link {
    z-index: 3;
    color: white;
    background-color: #27AE60;
}




