.containerTask{
    width: 1200px;
}

.title{
    display: flex;
    justify-content: space-between;
}


.taskHr{
    border-bottom: 1px solid #CECECE;
    width: 1300px;
}

.taskList{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.taskTitle{
    border: 1px solid #BDBDBD;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    margin-right: 15px;
    padding: 20px;
    max-height: 50px;
}

.mop{
    padding-left: 186px!important;
}

.flexList{
    display: flex;
}

.point {
    display: inline-block;
    width: 11px;
    height: 11px;
    background: #47CB7B;
    border-radius: 8px;
    margin-right: 5px;
}

.pointNot {
    display: inline-block;
    width: 11px;
    height: 11px;
    background: #EC5757;
    border-radius: 8px;
    margin-right: 5px;
}

.listPost{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px 48px;
    background: white;
    border-radius: 8px;
    margin-bottom: 15px;
}


.fonsTaskText{
    background-color: #F8F8F8;
    padding-right: 430px;
}

.completed{
    color: #68c968;
    font-weight:600;
}

.taskTextNeactive{
    color: #a2a2a2;
    font-weight: 600;
}

.taskLink> a {
    color: black;
}


.completed{
    color: #27AE60;
    font-weight:600;
}

.notCompleted {
    color: #EB5757;
    font-weight:600;
}

.exit{
    margin-top: 32px;
    font-size: 18px;
    cursor: pointer;
}

