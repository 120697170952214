.navigations button {
    font-size: 24px;
    margin-right: 10px;
    padding: 7px 20px;
    border-radius: 8px;
    border: none;
    opacity: 0.5;
    color: #222222;
    transition: 0.3s;
    font-weight: 600;
    background: #ffffff;

}

.navigations button:global(.active) {
    opacity: 1;
}