.addTasksLink {
    display: inline-block;
    transition: 0.3s;
    padding: 10px 20px;
    color: #ffffff;
    background: #47cb7b;
    text-align: center;
    border-radius: 10px;
    width: 100%;
}

.downloadPage__wrapper {
    margin-bottom: 40px;
}

.downloadPage__wrapper h2{
    font-weight: 600;
    margin: 0;
}

.addTasksLink:hover {
    background: #b7b7b7;
    color: white;
}

.twoColumn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    margin-bottom: 10px;
    padding: 10px 30px;
    border-radius: 8px;
}
.twoColumn li {
    padding: 10px 0px 10px 0px;
}


.changeBtn:first-child {
    background: #222222;
}

.changeBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border: none;
    background: #F0F0F0;
    transition: 0.3s;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 12px;
}

.number {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #F0F0F0;
    padding: 2px 12px;
    border-radius: 5px;
    margin-right: 10px;
}


.changeBtn img {
    max-height: 15px;
}

.changeBtn:hover {
    opacity: 0.6;
}

.changeBtn p {
    color: #222222;
}

.groups {
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: white;
    border-radius: 8px;
}


.groups p {
    margin-bottom: 0;
}

.groups__item {
    display: inline-flex;
    justify-content: space-between;
    width: 25%;
    padding-left: 5px;
}

.groups__item:last-child .changeBtn {
    margin-left: auto;

}

.filterBtn {
    transition: 0.3s;
    width: 100%;
    border: none;
    padding: 7.2px 15px;
    background: #222222;
    border-radius: 8px;
    color: #ffffff;
}

.filterBtn:hover {
    opacity: 0.5;
}

.resetBtn {
    transition: 0.3s;
    width: 100%;
    border: 1px solid #222222;
    padding: 7.2px 15px;
    border-radius: 8px;
    color: #222222;
}

.resetBtn:hover {
    opacity: 0.5;
}



.tasksBlock {
    margin-bottom: 20px;
}