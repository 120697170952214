.creteTestWrap {
    margin-bottom: 40px;
}


.testsPageWrapper h2 {
    font-weight: 600;
    margin: 0;
}

.creteTest {
    display: inline-block;
    transition: 0.3s;
    padding: 10px 20px;
    color: #ffffff;
    background: #47cb7b;
    text-align: center;
    border-radius: 10px;
    width: 100%;
}


.creteTest:hover {
    opacity: 0.6;
    color: #ffffff;
}

.testGroupLink {
    width: 100%;
    border-radius: 8px;
    border: none;
    background: white;
    padding: 8px 20px;
    box-sizing: border-box;
}

.navigation {
    margin-bottom: 30px;

}


.navigation button {
    font-size: 20px;
    border: none;
    background: none;
    padding: 10px 20px;
    display: inline-block;
    border-radius: 8px;
    margin-right: 15px;
    font-weight: 600;
    letter-spacing: 1px;
}

.navigation button:global(.active) {
    background: white;

}

