.stateInfo__wrapper {
    width: 100%;
}

.stateInfo__stages li {
    margin-bottom: 17px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.stateInfo__wrapper h2 {
    margin-bottom: 30px;
    font-weight: 600;
}

.stageInfo_title  {
    width: 86%!important;
    padding: 7px 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: none;
    text-align: left;
    background-color: #f5f5f5;
}

.title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 75px;
    text-transform: uppercase;
    line-height: 28px;
}

.stageInfo_title:global(.active) {
    background-color: #47CB7B;
    color: white;
}



.stateInfo__stages li button {
    transition: 0.3s;
    width: 10%;
    box-sizing: border-box;
    border-radius: 5px;
}

.stateInfo__stages li button:hover {
    opacity: 0.5;
}

.editBtn {
    background-color: #47CB7B;
    border: 1px solid rgba(71, 203, 123, 1);
}

.editBtn img {
    max-height: 20px;
}

.deleteBtn {
    border: 1px solid #b7b7b7;
    background-color: rgba(231, 76, 60, 0);
}

.deleteBtn img {
    max-height: 15px;
}

.stateInfo__noStage {
    font-size: 18px;
}

/*.stageInfo__form {*/
/*    margin: 30px 0;*/
/*    display: none;*/
/*}*/

.createStage__inputs_item {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;

}

.editStage__inputs  input, .editStage__inputs select{
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 0px 5px rgb(34 34 34 / 10%);
    width: 60%;
}


.createStage__inputs_item button {
    transition: 0.3s ease-in;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 0px 5px rgb(34 34 34 / 10%);
    width: 60%;
    background: white;
    font-weight: 600;
}

.createStage__inputs_item button:hover {
    border: none;
    box-shadow: 0px 0px 5px rgb(34 34 34 / 50%);
}

.createStage__inputs_item img {
    max-width: 20px;
    margin-right: 10px;
    margin-bottom: 4px;
}

.submitBtn {
    transition: 0.3s ease-in-out;
    display: block;
    margin-left: auto;
    padding: 5px 13px;
    border: none;
    background: #47CB7B;
    border-radius: 5px;
    color: white;
    margin-bottom: 20px;
}

.submitBtn:hover {
    background: #b7b7b7;
}