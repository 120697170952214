.wrapper h2 {
    font-weight: 600;
    margin-bottom: 30px;
}

.createThemeForm {
    background: white;
    padding: 30px;
    border-radius: 10px;
}


.createThemeForm input, .createThemeForm textarea, .createThemeForm select {
    padding: 10px;
    border-radius: 5px;
    border: none;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(34, 34, 34, 0.2);
}

.createThemeForm textarea {
    resize: none;
    min-height: 250px;
}

.createThemeForm label {
    width: 100%;
}

.createThemeForm span {
    display: inline-block;
    font-weight: 600;
    margin-bottom: 15px;
}

.btn {
    padding: 6px 12px;
    color: white;
    background: #47cb7b;
    border-radius: 5px;
    border: none;
}