

.title{
    display: flex;
    justify-content: space-between;
}


.taskHr{
    border-bottom: 1px solid #CECECE;
    width: 1300px;
}

.taskList{
    display: flex;
    justify-content: space-between;
}

.taskTitle{
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    border-radius: 8px;
    padding: 15px;
    color: rgba(34, 34, 34, 0.4);
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
}

.taskTitle:hover {
    color: #222222;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0);
}

.stages {
    width: 20%;
}

.stage {
    margin-bottom: 15px;
}

.taskTitleActive{
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    border-radius: 8px;
    padding: 15px;
    color: #222222;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0);
    margin-bottom: 15px;
}
.grade{
    margin-bottom: 20px;
}

.mop{
    padding-left: 186px!important;
}
.hideMark{
    display:none;
}

.ShowMark {
    width: 10%;
    height: 140px;
    border-radius: 8px;
    background: #DBF3E5;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}


.ShowMark span {
    color: #47cb7b;
}

.ShowMark span:last-child {
    font-size: 40px;
    font-weight: 600;
}

.flexList{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.listPost{
    display: flex;
    justify-content: space-between;
    width: 860px;
    padding-left: 10px;
    line-height: 2;
    margin-top: 5px;
}

.fonsTaskText{
    background-color: #F8F8F8;
    padding-right: 430px;
}

.completed{
    color: #68c968;
    font-weight:600;
}

.taskTextNeactive{
    color: #a2a2a2;
    font-weight: 600;
}
.taskLink{
    width: 67.5%;
}
.taskLink> a {
    color: black;

}

.yes{
    background-color: #DBF3E5;
}

.nov{
    background-color: #FFF0F0;
}

.completed2{
    color: #929292;
    font-weight:600;
}

.exit{
    margin-top: 32px;
    font-size: 18px;
    cursor: pointer;
}

