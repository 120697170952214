.fons{
    width: 400px;
    height: 100%;
    background-color: white;
    margin-top: 20px;
    padding: 16px;
}

.checkboxBtn{
    display: flex;
    align-items: center;
}

.checkboxBtn{
    border-bottom: 1px solid #CECECE;
    border-top: 1px solid #CECECE;
    width: 160px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
}

.checkboxBtn > input{
    width: 18px;
    height: 18px;
}

.flexSave{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.btn{
    width: 120px;
    height: 40px;
    background-color: #47CB7B;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border-radius: 10px;
    margin-top: 20px;
    margin-left: 10px;
}