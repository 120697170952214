.modal__wrap {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
    width: 30%;
    background: white;
    padding: 50px;
    overflow-y: auto;
    font-size: 18px;
    z-index: 2;
    border-radius: 8px;

}

.yesBtn {
    transition: 0.3s;
    margin-top: 20px;
    height: 35px;
    margin-right: 10px;
    border: none;
    background: #222222;
    color: white;
    border-radius: 5px;
}

.noBtn {
    transition: 0.3s;
    height: 35px;
    background: #e8e8e8;
    color: #222222;
    border: none;
    border-radius: 5px;
}