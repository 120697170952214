.modal_wrapper {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%,200px);
    max-height: 550px;
    width: 50%;
    background: white;
    padding: 50px;
    overflow-y: auto;
    border-radius: 8px;
}

.theme {
    border: 1px solid rgb(204, 204, 204);
    margin-bottom: 1.5rem;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 15px 25px;
}


.modal_wrapper h3 {
    margin-bottom: 30px;
}


.checkbox {
    width: 33%;
}


.applyBtn {
    transition: 0.3s;
    border-radius: 5px;
    background-color: #47cb7b;
    border: none;
    color: white;
    font-size: 20px;
    padding: 5px 10px;
}


.applyBtn:hover {
    opacity: 0.7;
}

.points {
    background: #dbf3e5;
    padding: 10px;
    border-radius: 8px;
    color: #47cb7b;
    margin-bottom: 30px;

}


