.logTable {
    overflow-x: auto;
    overflow-y: auto;
    border-radius: 8px;
    background: white;
    max-height: 720px;
}
.btnSearch{
   width: 100%;
}
.btnSearchForNo{
    width: 299px;
}

.logTableHeader {
    position: relative;
    display: flex;
    margin: 0;
}


.logTableHeader:first-child {
    position: sticky;
    top: 0;
    z-index: 2;
}


.logTableHeader:first-child li {
    background: white;
}

.logTableHeader:last-child li {
    border-bottom: none;
}

.headTable {
    color: #222222;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.logTableHeader .search_wrap {
    padding: 30px;
    background: white!important;
}
.search_wrap input, .liOfNames input, .liOfNamesNone  input{
    border-radius: 8px;
    padding: 5px 15px;
    border: none;
    background: #f5f5f5;

}

.liOfNames input {
    color: #47CB7B;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.liOfNamesNone input {
    color: #EB5757;
    font-weight: 600;
    letter-spacing: 0.5px;
}


.liOfNamesNone  input {
    text-align: center;
}

.liOfNames input {
    text-align: center;
}

.logTableHeader li {
    padding: 20px 30px;
    border-right: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 350px;
}

.logTableHeader li:last-child {
    border-right: none;
}

.logTableHeader:nth-child(odd) li:first-child{
    background: #effff5;
}

.logTableHeader:nth-child(even) li:first-child{
    background: #edf7ff;
}

.logTableHeader li:first-child {
    justify-content: start;
    min-width: 360px;
    z-index:1;
    position: sticky;
    left: 0;
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 0.5px;
}
.logTableHeaderForSearch li:first-child {
    justify-content: start;
    min-width: 360px;
    z-index:1;
    position: sticky;
    left: 0;
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 0.5px;
}
.grade {
    color: #47CB7B;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.showTaskBtn {
    margin-left: 7px;
    padding: 8px 5px;
    border: none;
}


.showTaskBtn span {
    position: relative;
    display: block;
    width: 5px;
    height: 5px;
    background: #222222;
    border-radius: 50%;
    margin-bottom: 3px;
}

.createDate {
    background: #27AE60;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 6px 12px;
    margin-left: 10px;
}

.date {
    padding: 6px 12px;
    border: none;
    background: #ffffff;
    border-radius: 5px;
}

.delBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border: none;
    background: #F0F0F0;
    transition: 0.3s;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 12px;
}

.delBtn:first-child {
    background: #222222;
}

.delBtn img {
    max-height: 15px;
}


.delBtn:hover {
    opacity: 0.6;
}






