.createThemeForm {
    background: white;
    padding: 30px;
    border-radius: 10px;
}

.createThemeForm input, .createThemeForm textarea, .createThemeForm select {
    padding: 10px;
    border-radius: 5px;
    border: none;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(34, 34, 34, 0.2);
}

.createThemeForm textarea {
    resize: none;
    min-height: 250px;
}

.createThemeForm label {
    width: 100%;
}

.createThemeForm span {
    display: inline-block;
    font-weight: 600;
    margin-bottom: 15px;
}

.title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 15px;
}

.question {
    transition: 0.3s;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    width: 100%;
    background: #f5f5f5;
    box-sizing: border-box;
    margin-bottom: 10px;
    cursor: pointer;
}


.question:global(.active) {
    transition: 0.3s;
    background: #47CB7B;
    color: white;
}


.question:global(.active) span {
    color: #222222;
}


.question span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    border-radius: 5px;
    margin-right: 10px;
    background: white;
    font-weight: 500;
    margin-bottom: 0;
}

.answer {
    display: flex;
}

.answerList {
    display: flex;
    margin-bottom: 10px;
}

.answer button, .answerList button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    border: none;
    background: #47CB7B;
    color: white;
    font-weight: 600;
    font-size: 20px;
    border-radius: 5px;
    margin-left: 10px;
}

.answerList button {
    transition: 0.3s;
    background: #F0F0F0;
}

.answerList button:hover {
    opacity: 0.6;
}

.answerList button img {
    max-height: 16px;
}

.oneAnswer {
    margin-bottom: 2rem;
}

.file {
    display: none;
}

.fileWrapper {
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    border: none;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(34, 34, 34, 0.2);
}

.fileWrapper img {
    max-height: 20px;
    margin-right: 10px;
}

.fileWrapper:hover {
    opacity: 0.6;
}

.questions {
    max-height: 625px;
    overflow-y: auto;
}

.createBtn {
    transition: 0.3s;
    padding: 10px 20px;
    color: #ffffff;
    background: #47cb7b;
    text-align: center;
    border-radius: 10px;
    border: none;
    margin-left: auto;
}

.cancelBtn {
    transition: 0.3s;
    padding: 10px 20px;
    color: #6d6d6d;
    background: #F0F0F0;
    text-align: center;
    border-radius: 10px;
    border: none;
    margin-left: auto;
    margin-right: 15px;
}


.createBtn:hover, .cancelBtn:hover {
    opacity: 0.6;
}

.imageWrap {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: none;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(34, 34, 34, 0.2);
}

.imageWrap button {
    transition: 0.3s;
    border: none;
    background: #47cb7b;
    color: white;
    border-radius: 5px;
    width: 32px;
    height: 32px;
    top: -10px;
    right: -10px;
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.imageWrap button img {
    max-height: 15px;
}


.imageWrap button:hover {
    opacity: 0.6;
}

.imageWrap img{
    max-width: 100%;
}