.resetBtn{
    border: none;
    border-radius: 5px;
    background: #E1E1E1;
    color: #222222;
    padding: 10px
}
.sendBtn{
    border: none;
    border-radius: 5px;
    background: #222;
    color: #E1E1E1;
    padding: 10px;
    margin-left:10px;
}
.showAnswerBtn{
    width: 100%;
    border: none;
    border-radius: 20px;
    background: #222;
    color: #E1E1E1;
    padding: 10px;
}
.tableThead{
    margin-top: 20px;
    background: #222222;
    color: #E1E1E1;
}