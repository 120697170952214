.wrapper h2 {
    font-weight: 600;
    margin-bottom: 30px;
}



.link {
    transition: 0.3s;
    color: white;
    display: inline-block;
    padding: 15px 30px;
    background: #47cb7b;
    margin-right: 15px;
    border-radius: 5px;
}


.link:hover {
    opacity: 0.5;
    color: white;
}

.menuName {
    font-size: 18px;
}