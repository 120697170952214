.btn__wrap {
    position: fixed;
    top: 15px;
    background-color: white;
    left: 15px;
    height: calc(100vh - 30px);
    border-radius: 10px;
    padding: 60px 40px;
    display: flex;
    flex-direction: column;
    min-width: 17%;
}

.exitBtn {
    transition: 0.3s;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 10px;
    border: none;
    background: none;
}

.exitBtn:hover {
    background: #e6e6e6;
}

.icon_wrap {
    width: 36px;
    height: 36px;
    margin-right: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: #222;
}

.icon_wrap svg {
    color: white;
}

.menuName{
    color:#222222;
    font-weight: 600;
}