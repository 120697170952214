.wrap {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner {
    position: relative;
    width: 95%;
    height: 93%;
    background: white;
    overflow-y: auto;
    padding: 45px 90px;
    display: flex;
    flex-direction: column;
}

.link {
    margin-top: auto;
    text-align: center;
}

.closeBtn {
    transition: 0.3s;
    position: absolute;
    right: 52px;
    top: 32px;
    border: none;
    background: none;
}

.closeBtn:hover {
    color: #4cd964;
}


.goToTest {
    transition: 0.3s;
    display: inline-block;
    text-align: center;
    margin: auto;
    background: #4cd964;
    padding: 10px 20px;
    color: white;
    border-radius: 8px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
}

.goToTest:hover {
    opacity: 0.6;
    color: white;
}


.inner h5 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}

.inner h2 {
    background: #F2F2F2;
    padding: 18px 10px;
    width: 33%;
    font-weight: 600;
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 35px;
}

.inner p {
    margin-bottom: 10px;
}

.inner h3 {
    text-transform: uppercase;
    color: #4cd964;
    font-weight: 600;
    width: 50%;
    line-height: 33px;
    margin-bottom: 35px;
    font-size: 30px;
}

.inner h4{
    text-transform: uppercase;
    color: #4cd964;
    font-weight: 600;
    line-height: 33px;
    margin-bottom: 15px;
}