.menu__wrap {
    position: fixed;
    top: 15px;
    background-color: white;
    left: 15px;
    height: calc(100vh - 30px);
    border-radius: 10px;
    padding: 60px 40px;
    display: flex;
    flex-direction: column;
    min-width: 17%;
}

.menu__block{
    display:flex;
    align-items: center;
    margin-bottom: 20px;
}

.link svg {
    color: #fff;
}
.menuName{
    color:#222222;
    font-weight: 600;
}

.icon_wrap {
    width: 36px;
    height: 36px;
    margin-right: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: #47CB7B;
}


.icon_wrap img {
    max-height: 18px;
}

.link:nth-child(2) .icon_wrap {
    background: #F2994A;
}

.link:nth-child(3) .icon_wrap {
    background: #56CCF2;
}

.link {
    transition: 0.3s;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 10px;
}

.link:hover {
    background: #e6e6e6;
}

.exitBtn {
    transition: 0.3s;
    margin-top: auto;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 10px;
    border: none;
    background: none;
}

.exitBtn .icon_wrap {
    background: #222222;
}

.exitBtn:hover {
    background: #e6e6e6;
}



.link:global(.active) {
    background: #e6e6e6;
}

