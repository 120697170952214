.info__wrapper {
    background: white;
    padding: 20px 30px;
    border-radius: 10px;
    overflow-y: auto;
    height: 500px;
}

.info__tags {
    background: #222222;
    padding: 20px 30px;
    color: white;
    border-radius: 10px;
    font-weight: 600;
    letter-spacing: 1px;
}

.info_pass_count {
    padding: 20px 30px;
    border-radius: 10px;
    box-sizing: border-box;
    border: none;
    text-align: center;
    width: 100px;
    color: #222222;
}

.info_pass_countText {
    font-weight: 600;
    font-size: 18px;
    margin-right: 20px;
}

.info_send_btn {
    transition: 0.3s;
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    background: #47CB7B;
    color: white;
    font-size: 18px;
}

.info_send_btn:hover {
    opacity: 0.6;
}