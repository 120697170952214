.stageTasksList__wrapper {
    width: 100%;

}

.title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 70px;
    text-transform: uppercase;
    line-height: 28px;
}

.list {
    min-height: 400px;
    max-height: 720px;
    overflow-y: auto;
    padding: 5px 10px;
}

.task {
    box-shadow: 0px 0px 5px rgb(34 34 34 / 10%);
    padding: 10px;
    width: 100%;
    margin-bottom: 17px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

.number {
    padding: 2px 10px;
    background: #F0F0F0;
    border-radius: 5px;
    margin-right: 10px;
}

.point {
    padding: 2px 10px;
    background: #DBF3E5;
    color: #47cb7b;
    border-radius: 5px;
    margin-left: 10px;
    font-weight: 600;
}