.link {
    border: none;
    font-weight: 600;
    background: none;
    margin-right: 20px;
    border:none;
    text-transform: capitalize;
    font-size: 20px;
    opacity: 0.6;
    margin-bottom: 30px;
}

.linkActive {
    font-weight: 600;
    font-size: 20px;
    border:none;
    border-bottom: 3px #47cb7b solid;
    background: none;
    margin-right: 20px;
    text-transform: capitalize;
    margin-bottom: 30px;
}