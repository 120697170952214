.title {
    position: relative;
    margin-bottom: 50px;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.title h2{
    font-size: 24px;
    font-weight: 600;
}

.title span {
    position: fixed;
    right: 5%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    background: #222222;
    color: white;
    border-radius: 50%;
    font-size: 18px;
}

.questionWrap {
    background: white;
    margin-bottom: 30px;
    padding: 30px;
    border-radius: 8px;
}

.questionWrap .checkFrom{
    margin-bottom: 10px;
}

.questionWrap h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
    width: 50%;
    line-height: 28px;
}

.hint {
    color: #828282;
    font-weight: 600;
    letter-spacing: 0.5px;

}


.submitBtn {
    background: #47cb7b;
    border: none;
    border-radius: 5px;
    color: #fff;
    display: block;
    margin-left: auto;
    margin-top: 10px;
    padding: 10px 25px;
    transition: .3s ease-in-out;
}

.submitBtn:hover {
    opacity: 0.5;
}

.questionImage{
    max-width: 500px;
    width: 100%;
    margin-bottom: 40px;
}