.modal_wrapper {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%,200px);
    max-height: 550px;
    width: 50%;
    background: white;
    padding: 50px;
    overflow-y: auto;
}


.modal_wrapper h3 {
    margin-bottom: 30px;
}

.checkboxes {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.checkbox {
    width: 33%;
}


.applyBtn {
    transition: 0.3s;
    border-radius: 5px;
    background-color: #47cb7b;
    border: none;
    color: white;
    font-size: 20px;
    padding: 5px 10px;
}


.applyBtn:hover {
    opacity: 0.7;
}


