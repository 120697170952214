.createTasksWrapper {
    background: white;
    padding: 50px 30px;
    border-radius: 10px;
}



.saveBtn{
    padding: 6px 14px;
    border: none;
    background: #47CB7B;
    border-radius: 5px;
    color: white;
}
.tagBtn{
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: none;
    cursor: pointer;
    width: 100%;
    background: white;
    margin-bottom: 10px;
    box-shadow: 0px 0px 5px rgba(34, 34, 34, 0.1);
}
.loadBtn{
    padding: 5px 13px;
    border:1px solid  #C5C5C5;
    border-radius: 5px;
    background: none;
    margin-left:10px;
}
.inputStyle{
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: none;
    min-height:40px;
    box-shadow: 0px 0px 5px rgba(34, 34, 34, 0.1);
    width: 100%;

}
.textArea{
    padding: 10px;
    resize: none;
    border-radius: 5px;
    border: none;
    width:100%!important;
    height: 0!important;
    min-height: 450px!important;
    box-shadow: 0px 0px 5px rgba(34, 34, 34, 0.1) !important;
}

.boldText {
    display: inline-block;
    width: 100%;
    font-weight: 600;
    margin-bottom: 17px;
}

.inputFile {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
}

.inputFile_btn {
    display: inline-block;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #b7b7b7;
    margin-left: 10px;
    margin-right: 10px;
}

.inputFile_btn svg {
    margin-right: 10px;
}

.without_tasks {
    font-size: 20px;
    color: #222222;
}

.projectCheck {
    transition: 0.3s;
    cursor: pointer;
    padding: 5px 15px;
    background: #F0F0F0;
    border-radius: 8px;
}

.projectCheck:hover {
    background: #cccccc;
}