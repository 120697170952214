.testWrapper {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}


.firstLinkNot {
    transition: 0.3s;
    display: flex;
    justify-content: space-between;
    width: 79%;
    padding: 16px 48px;
    background: white;
    border-radius: 8px;
    margin-bottom: 15px;
    color: #222222;
    align-items: center;
    cursor: not-allowed;
}

.firstLinkNot:hover{
    color: #EC5757;
}

.firstLinkNot span {
    border-right: 1px solid #bdbdbd;
}


.firstLinkNot span:last-child {
    border-right: none;
}


.firstLink {
    transition: 0.3s;
    display: flex;
    justify-content: space-between;
    width: 79%;
    padding: 16px 48px;
    background: white;
    border-radius: 8px;
    margin-bottom: 15px;
    color: #222222;
    align-items: center;
}


.firstLink span {
    border-right: 1px solid #bdbdbd;
}


.firstLink span:last-child {
    border-right: none;
}

.firstLink:hover {
    color: #47cb7b;
}

.secondLink {
    align-items: center;
    text-align: center;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    width: 20%;
    padding: 16px 48px;
    background: white;
    border-radius: 8px;
    margin-bottom: 15px;
    color: #222222;
    border: none;
}

.secondLink img {
    display: inline-block;
    margin-right: 6px;
}

.secondLink:hover {
    color: #47cb7b;
}

.notAllowed {
    color: #EC5757;
    width: 25%;
    text-align: center;
    font-weight: 600;
}


.notAllowed i {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 8px;
    background: #EC5757;
    margin-right: 6px;
}

.allowed {
    width: 25%;
    text-align: center;
    color: #47cb7b;
    font-weight: 600;
}

.allowed i {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 8px;
    background: #47cb7b;
    margin-right: 6px;
}

.date {
    text-align: center;
    color: #919191;
}
