.createStage__wrapper {
    width: 100%;
}

.createStage__wrapper h2 {
    margin-bottom: 30px;
    font-weight: 600;
}


.createStage__inputs_item {
    width: 100%;
    margin-bottom: 1rem;

}


.createStage__inputs_item span{
    display: inline-block;
    margin-bottom: 17px;
    font-weight: 600;
}



.title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 35px;
    text-transform: uppercase;
    line-height: 28px;
}

.createStage__inputs  input, .createStage__inputs  select{
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 0px 5px rgb(34 34 34 / 10%);
    width: 100%;
}


.createStage__inputs_item button {
    transition: 0.3s ease-in;
    padding: 5px 10px;
    box-sizing: border-box;
    border: none;
    box-shadow: 0px 0px 5px rgb(34 34 34 / 10%);
    width: 100%;
    background: white;
    border-radius: 5px;
    font-weight: 600;
}

.createStage__inputs_item button:hover {
    border: none;
    box-shadow: 0px 0px 5px rgb(34 34 34 / 50%);
}

.createStage__inputs_item img {
    max-width: 20px;
    margin-right: 10px;
    margin-bottom: 4px;
}

.submitBtn {
    transition: 0.3s ease-in-out;
    display: block;
    margin-left: auto;
    padding: 5px 13px;
    border: none;
    background: #47CB7B;
    border-radius: 5px;
    color: white;
}

.submitBtn:hover {
    background: #b7b7b7;
}