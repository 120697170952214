.articleUl > ul > li {
    list-style: none;
    line-height: 3;
    width: 124px;
}

.articleUl > ul > a > li {
    list-style: none;
    line-height: 3;
    color: black;
}

.home {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #222222;
    border-radius: 10px;
    width: 45px;
    height: 45px;
    padding: 5px;
}

.home > svg {
    color: white;
}

.save {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: black;
    width: 45px;
    height: 45px;
    padding: 5px;
    margin-top: 10px;
}

.page {
    padding-top: 40px;
}

.textHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 20px 20px 30px;
    background: white;
    border-radius: 8px;
    margin-bottom: 10px;
}


.carHeader {
    width: 50px;
    height: 50px;
    background-color: #47CB7B;
    border-radius: 10px;
}


.carHeader > img {

    padding: 12px;
}

.headerFlex {
    display: flex;
    margin-bottom: 20px;
}

.cars {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 25%;

}


.dateFilter {
    background: #ffffff;
    height: 30px;
    margin-left: 5px;
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
}

.dateFilter img {
    margin-right: 5px;
    margin-bottom: 3px;
}

.cars button img {
    max-width: 18px;
}

.cars > p {
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-bottom: 0;

}

.cars select {
    width: 50%;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    border: none;
    outline: none !important;
}


.cars button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 8px;
}

.cars button img {
    width: 24px;
}

.borderBottom {
    border-bottom: 1px solid #E5E5E5;
    position: absolute;
    width: 1200px;
    margin: -25px 0px 0px 105px;
}

.listArticle {
    padding-left: 66px;
}

.articleUl > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nameLisk {
    border-bottom: 1px solid #CECECE;
}

.btnList {
    border: 2px solid #222222;
    padding: 0px 25px 0px 25px;
    border-radius: 6px;
}

.btnList:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}

.table {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.groups {
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: white;
    border-radius: 8px;
    margin-bottom: 10px;
}


.groups p {
    margin-bottom: 0;
}

.groups__item {
    display: inline-flex;
    justify-content: space-between;
    width: 25%;
    padding-left: 5px;
}

.groups__item:last-child .changeBtn {
    margin-left: auto;

}

.changeBtn {
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 47%;
    background: #F0F0F0;
    padding: 5px 10px;
    border-radius: 10px;
    color: #222222;
    margin: 3px;
}

.changeBtn:hover {
    background: #222222;
    color: #F8F8F8;
}

.changeBtn p {
    color: #222222;
}

.time {
    font-size: 14px !important;
}
