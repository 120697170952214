.createTestsWrapper h2 {
    font-weight: 600;
    margin-bottom: 40px;
}

.createTestsWrapper h4 {
    font-size: 16px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 15px;
}

.step {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 35px;
    text-transform: uppercase;
    line-height: 28px;
}

.stepDisabled {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 35px;
    text-transform: uppercase;
    color: #969696;
    line-height: 28px;
}

.createTestsForm {
    background: white;
    padding: 50px 30px;
    border-radius: 10px;
}

.label {
    width: 100%;
}

.labelDisabled {
    width: 100%;
}

.label input, .label select, .label button {
    cursor: pointer;
}

.labelDisabled input, .labelDisabled select, .labelDisabled button {
    cursor: not-allowed;
}

.labelDisabled input, .labelDisabled select, .labelDisabled button, .labelDisabled .checkTheme {
    padding: 10px;
    border-radius: 5px;
    border: none;
    width: 100%;
    background: #f1f1f1;
}


.label input, .label select, .label button, .label .checkTheme {
    padding: 10px;
    border-radius: 5px;
    border: none;
    width: 100%;
    box-shadow: 0px 0px 5px rgba(34, 34, 34, 0.1);
}

.labelThemes input {
    padding: 3px 10px;
    border-radius: 5px;
    border: 1px solid #222222;
    max-width: 100px;
}


.labelDisabled span {
    display: inline-block;
    margin-bottom: 17px;
    color: #969696;
}

.label span {
    display: inline-block;
    font-weight: 600;
    margin-bottom: 17px;
}

.checkboxes {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    border-radius: 5px;
    max-height: 500px;
    overflow-y: auto;
}

.checkbox {
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.submitBtn {
    transition: 0.3s;
    background: #47cb7b;
    color: white;
    padding: 12px 30px;
    border: none;
    border-radius: 5px;
}

.submitBtn:hover {
    opacity: 0.5;
}

.submitBtnDisabled {
    background: #f1f1f1;
    color: #969696;
    padding: 12px 30px;
    border: none;
    border-radius: 5px;
    cursor: not-allowed;
}
