

.wrapperSettingsGroups {
    background: white;
    padding: 50px 30px;
    border-radius: 10px;
}



.groupInfo {
    width: 100%;
    margin-bottom: 50px;
    border-radius: 8px;
}

.groupInfo h2 {
    margin-bottom: 30px;
    font-weight: 600;
}


.groupInfo_item {
    width: 100%;
    margin-bottom: 1rem;

}

.groupInfo_item span {
    display: inline-block;
    margin-bottom: 17px;
    font-weight: 600;
}

.title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 35px;
    text-transform: uppercase;
    line-height: 28px;
}

.groupInfo_item input, .groupInfo_item select {
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 0px 5px rgb(34 34 34 / 10%);
    width: 100%;
}



.submitBtn {
    transition: 0.3s ease-in-out;
    display: block;
    margin-top: 10px;
    margin-left: auto;
    padding: 5px 13px;
    border: none;
    background: #47CB7B;
    border-radius: 5px;
    color: white;
}


.submitBtn:hover {
    background: #b7b7b7;
}




