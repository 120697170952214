.testWrapper {
    margin-top: 30px;
    width: 100%;
    background: white;
    padding: 40px 30px 0 30px ;
    border-radius: 8px;
}

.testWrapper h2 {
    margin-bottom: 40px;
}


.testInfo {
    margin-bottom: 40px;
}


.testInfo:nth-child(even)  .test{
    border-left: 2px solid #F2994A;
}

.test {
    width: 100%;
    display: inline-block;
    border-left: 2px solid #27AE60;
    padding: 10px  0 10px 20px;
    color: #222222;
}

.description {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

.description span {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 600;
}

.description  p {
    margin-bottom: 0;
}

.btns {
    display: inline-flex;
    align-items: center;
}

.btn {
    transition: 0.3s ease-out;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: none;
    margin-left: 10px;
    background: #F0F0F0;
    border-radius: 5px;
    color: #7e7e7e;
}


.btn:hover {
    opacity: 0.6;
}


.btn:first-child {
    background: #222222;
}

.btn img {
    max-height: 16px;
}

.select {
    border: none;
    border-radius: 8px;
    padding: 13px 26px;
    font-size: 18px;
    text-transform: capitalize;
}

.addQuestion {
    transition: 0.3s ease-in;
    padding: 7px 15px;
    border: 1px solid #4F4F4F;
    color: #4F4F4F;
    border-radius: 8px;
}


.addQuestion:hover {
    color: white;
    background: #4F4F4F;
}


