.menuWrap {
    padding: 55px 0;
    width: 100%;
    display: inline-flex;
}

.link {
    transition: 0.3s;
    font-size: 24px;
    color: rgba(34, 34, 34, 0.4);
    font-weight: 600;
    margin-right: 3%;
    padding: 5px 0;
    border-bottom: 3px solid rgba(71, 203, 123, 0);
}


.link:hover {
    color: #222222;
}


.link:global(.active) {
    border-bottom: 3px solid rgba(71, 203, 123, 1);
    color: #222222;
}

.close {
    color: #222222;
    margin-left: auto;
    text-align: right;
}