.wrapper {
    padding: 55px 0;
    width: 100%;
}



.wrapper button {
    transition: 0.3s;
    border: none;
    background: #222222;
    width: 45px;
    height: 45px;
    border-radius: 5px;
}


.wrapper button:hover {
    opacity: 0.6;
}


.wrapper svg {
    color: white;
}