.info__wrapper {
    background: white;
    padding: 20px 30px;
    border-radius: 10px;
    overflow-y: auto;
    height: 411px;
    margin-bottom: 24px;
    overflow-y: auto;
    overflow-x: auto;

}

.info__tags {
    background: #222222;
    padding: 20px 30px;
    color: white;
    border-radius: 10px;
    font-weight: 600;
    letter-spacing: 1px;
}


.result span {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;

}

.result .wait {
    color: #222222;
}

.result .complete {
    color: #47CB7B;
}

.result .notComplete {
    color: #EB5757;
}


.result span:first-child {
    margin-right: 15px;
}

.pass_count {
    display: inline-block;
    padding: 20px 30px;
    border-radius: 10px;
    background: white;
    font-weight: 600;
}


.icon {
    transition: 0.1s ease-out;
    border: 1px solid #222222;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    width: 35px;
    height: 35px;
    margin-left: 10px;
}

.icon svg {
    transform: rotate(180deg);
}

.next {
    transition: 0.1s ease-out;
    font-size: 20px;
    display: inline-flex;
    align-items: center;
    color: #222222;

}

.next:hover {
    color: #47CB7B;
}

.next:hover .icon {
    border: 1px solid #47CB7B;
}

.reset {
    transition: 0.3s;
    text-transform: capitalize;
    padding: 10px 20px;
    margin-right: 15px;
    border-radius: 8px;
    border: 1px solid #222222;
}

.reset:hover {
    opacity: 0.6;
}

.send {
    transition: 0.3s;
    text-transform: capitalize;
    padding: 10px 40px;
    border-radius: 8px;
    border: 1px solid rgb(71, 203,123,  0);
    background-color: #47CB7B;
    color: white;
}

.send:hover {
    opacity: 0.6;
}


.showResultBtn {
    transition: 0.3s ease-out;
    margin: 30px 0;
    display: block;
    padding: 7px 20px;
    border-radius: 8px;
    border: none;
    background: #222222;
    color: white;
}

.showResultBtn:hover {
    opacity: 0.6;
}

.table {
    display: inline-block;
    padding: 20px 30px;
    border-radius: 10px;
    background: white;
}

.notCompleteTwo {
    color: #EB5757;
    font-size: 18px;
}
